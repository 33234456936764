import axios from 'axios';
import { t } from 'i18next';

import { Capacitor } from '@capacitor/core';
import type { DeviceId, DeviceInfo } from '@capacitor/device';
import type { LocalNotificationsPlugin } from '@capacitor/local-notifications';
import type { PushNotificationSchema, PushNotificationsPlugin, Token } from '@capacitor/push-notifications';

import { isNative } from '../helpers/utils.helper';
import { history } from '../history';
import { setNotificationUrl } from '../pages/Login/loginSlice';
import { getUsedNotification, setUsedNotification } from '../pages/Permissions/permissions.service';
import { store } from '../store';
import { RaygunErrorHandlerService } from './raygun.service';

const { logError } = RaygunErrorHandlerService();

type DeviceParams = {
  notification_token: string;
  biometrics_supported: boolean;
  biometrics_enabled: boolean;
  notification_enabled: boolean;
  location_enabled: boolean;
};

type DeviceData = {
  device_native_id: DeviceId['identifier'];
  device_os: DeviceInfo['platform'];
  device_os_version: DeviceInfo['osVersion'];
  device_model: DeviceInfo['model'];
  window_screen_height: number;
  window_screen_width: number;
} & Partial<DeviceParams>;

type NotificationData = {
  push_type: 'feedback' | 'badge' | 'reminder';
  product_id: string;
  product_schedule_id: string;
};

export const upsertDevice = async (deviceParams: Partial<DeviceParams>) => {
  try {
    const { Device } = await import('@capacitor/device');
    if (Capacitor.isPluginAvailable('Device')) {
      const { identifier: device_native_id } = await Device.getId();
      const { platform: device_os, model: device_model, osVersion: device_os_version } = await Device.getInfo();
      const data: DeviceData = {
        device_native_id,
        device_os,
        device_model,
        device_os_version,
        window_screen_height: window.screen.height,
        window_screen_width: window.screen.width,
        ...deviceParams,
      };
      const response = await axios.post<string>('/v4_upsert_device', data);
      return response;
    }
  } catch (error) {
    logError(error, ['notification.service', 'upsertDevice']);
  }
};

const setNotificationInitiated = () => {
  localStorage.setItem('user-init-notification', 'true');
};

const getNotificationInitiated = () => {
  return localStorage.getItem('user-init-notification') === 'true';
};

class PushNotification {
  private pushNotifications: PushNotificationsPlugin | null;
  private localNotifications: LocalNotificationsPlugin | null;

  constructor() {
    this.pushNotifications = null;
    this.localNotifications = null;
  }

  private async checkDeviceAndRequestPermissions(): Promise<boolean> {
    const { PushNotifications } = await import('@capacitor/push-notifications');
    const { LocalNotifications } = await import('@capacitor/local-notifications');
    this.pushNotifications = PushNotifications;
    this.localNotifications = LocalNotifications;
    const platform = Capacitor.getPlatform() as DeviceInfo['platform'];

    if (
      platform === 'web' ||
      (platform === 'ios' && !Capacitor.isPluginAvailable('PushNotifications')) ||
      (platform === 'android' && !Capacitor.isPluginAvailable('LocalNotifications'))
    ) {
      return false;
    }

    const notInitiatedYet = !getNotificationInitiated();

    if (platform === 'ios') {
      const { receive } = notInitiatedYet
        ? await this.pushNotifications.requestPermissions()
        : await this.pushNotifications.checkPermissions();

      if (!receive && receive !== 'granted') {
        alert('You must grant push notification permission for this app to work properly');
        return false;
      }
    }

    if (platform === 'android') {
      const { display } = notInitiatedYet
        ? await this.localNotifications.requestPermissions()
        : await this.localNotifications.checkPermissions();

      if (!display && display !== 'granted') {
        alert('You must grant push notification permission for this app to work properly');
        return false;
      }
    }

    if (notInitiatedYet) {
      setNotificationInitiated();
    }

    return true;
  }

  public async register(): Promise<boolean> {
    const isDeviceReady = await this.checkDeviceAndRequestPermissions();

    if (!isDeviceReady) {
      return false;
    }

    // clear listeners before adding new ones (to avoid listener stacking bugs)
    await this.pushNotifications?.removeAllListeners();

    await this.addListeners();

    await this.pushNotifications?.register();

    return true;
  }

  private async addListeners() {
    await this.pushNotifications?.addListener('registration', async (token: Token) => {
      await upsertDevice({ notification_token: token.value });
    });

    await this.pushNotifications?.addListener('registrationError', (event) => {
      logError(event.error, ['notification', 'PushNotification', 'registrationError']);
    });

    await this.pushNotifications?.addListener('pushNotificationReceived', async (event) => {
      const { App } = await import('@capacitor/app');
      if (Capacitor.isPluginAvailable('App')) {
        const state = await App.getState();
        await handleNotification(event, state.isActive ? 'foreground' : 'background');
      }
    });

    await this.pushNotifications?.addListener('pushNotificationActionPerformed', async (event) => {
      await handleNotification(event.notification, 'background');
    });
  }
}

const handleNotification = async (notification: PushNotificationSchema, ground: 'foreground' | 'background') => {
  if (notification) {
    const { push_type, product_id, product_schedule_id } = notification.data as NotificationData;

    if (ground === 'background' && Capacitor.isPluginAvailable('SplashScreen')) {
      // Hide splash screen if app started from push notification
      const { SplashScreen } = await import('@capacitor/splash-screen');
      SplashScreen.hide();
    }

    if (ground === 'foreground') {
      const { Dialog } = await import('@capacitor/dialog');
      if (!Capacitor.isPluginAvailable('Dialog')) {
        return;
      }

      try {
        const { value: confirmed } = await Dialog.confirm({
          title: notification.title,
          message: notification.body || '',
          okButtonTitle: t('preferencesPage.ok'),
          cancelButtonTitle: t('preferencesPage.cancel'),
        });

        if (!confirmed) {
          return;
        }
      } catch (error) {
        logError(error, ['notification.service', 'handleNotification', 'Dialog.confirm']);
      }
    }

    let url = '';
    if (push_type === 'feedback') {
      url = `/tabs/feedback/${notification.id}`;
    }
    if (push_type === 'badge') {
      url = `/tabs/badges/${notification.id}`;
    }
    if (push_type === 'reminder') {
      url = `/tabs/home/${notification.id}?product_id=${product_id}&product_schedule_id=${product_schedule_id}`;
    }

    if (url) {
      store.dispatch(setNotificationUrl(url));
      history.push(url);
    }
  }
};

export const initNativeNotification = async (firstTime?: boolean) => {
  const usedNotification = getUsedNotification();
  if (firstTime || usedNotification) {
    await new PushNotification().register();
    setUsedNotification();
  }
};

export const checkNotificationPermission = async () => {
  let hasPermission = false;

  if (isNative() && getUsedNotification()) {
    const { LocalNotifications } = await import('@capacitor/local-notifications');
    const { PushNotifications } = await import('@capacitor/push-notifications');
    const platform = Capacitor.getPlatform() as DeviceInfo['platform'];

    if (platform === 'android' && Capacitor.isPluginAvailable('LocalNotifications')) {
      const { display } = await LocalNotifications.checkPermissions();
      hasPermission = display === 'granted';
    }

    if (platform === 'ios' && Capacitor.isPluginAvailable('PushNotifications')) {
      const { receive } = await PushNotifications.checkPermissions();
      hasPermission = receive === 'granted';
    }
  }

  return hasPermission;
};
