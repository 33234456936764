import type { AxiosError } from 'axios';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CheckUsVsCanada } from '../../helpers/utils.helper';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import { setErrorToast, TABS_TOAST } from '../tabs/tabsSlice';

const { logError } = RaygunErrorHandlerService();

type ForgotPasswordResponse = { message: string };

type ForgotPasswordSliceType = {
  status: APIStatus;
  errorText: string;
};

const initialState: ForgotPasswordSliceType = {
  status: APIStatus.IDLE,
  errorText: '',
};

export const forgotPassword = createAsyncThunk<
  [ForgotPasswordResponse, ForgotPasswordResponse] | [],
  string,
  AsyncThunkConfig
>('forgotPassword/forgotPassword', async (email, thunkAPI) => {
  try {
    const responses = await CheckUsVsCanada<ForgotPasswordResponse>('forgot_password', { email });
    thunkAPI.dispatch(setErrorToast(TABS_TOAST.FORGOT_PWD_SENT));
    return responses ?? [];
  } catch (e) {
    logError(e, ['forgotPasswordSlice', 'forgotPassword']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    clearForgotPasswordState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state, _action) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        if (action.payload[0]?.message) {
          state.status = APIStatus.FULFILLED;
        } else if (action.payload[1]?.message) {
          state.status = APIStatus.FULFILLED;
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        if (action.payload) {
          state.status = APIStatus.ERROR;
          state.errorText = (action.payload as ErrorMessage).displayText;
        }
      });
  },
});

export const { clearForgotPasswordState } = forgotPasswordSlice.actions;
