import { getUserOrganization, UserOrganization } from '../pages/Login/loginSlice';
import { useAppSelector } from '../store';

export const useUserOrg = () => {
  const { authResult } = useAppSelector((state) => state.login);
  const userOrg = getUserOrganization(authResult);
  const isAPA = userOrg === UserOrganization.APA;
  const isMKI = userOrg === UserOrganization.MKI;
  const isOnTrak = userOrg === UserOrganization.ONTRAK;
  const isOSI = userOrg === UserOrganization.OSI;
  const isYale = userOrg === UserOrganization.YALE;

  return { isAPA, isMKI, isOnTrak, isOSI, isYale };
};
