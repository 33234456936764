import axios from 'axios';

import { isPlatform } from '@ionic/react';

import { environment } from '../environment/environment';
import { RaygunErrorHandlerService } from '../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export const sample = <T>(array: T[] | undefined) => {
  const length = array == null ? 0 : array.length;
  return array && length ? array[Math.floor(Math.random() * length)] : undefined;
};

export const isNative = () => (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');

export const getCustomIcon = (icon: string) => {
  let src = `assets/icons/${icon}.svg`;
  if (process.env.NODE_ENV === 'test') {
    src = 'public/' + src;
  }
  const url = new URL(src, window.location.origin);
  return url.href;
};

export const CheckUsVsCanada = async <T>(endpoint: string, body?: object) => {
  try {
    const responses = await axios.all([
      axios.post(environment.CAEndpoint + endpoint, body),
      axios.post(environment.USEndpoint + endpoint, body),
    ]);
    return responses as [T, T];
  } catch (error) {
    logError(error, ['store', 'CheckUsVsCanada']);
    return undefined;
  }
};
