import type { AxiosError } from 'axios';
import axios from 'axios';
import type { History } from 'history';
import { jwtDecode } from 'jwt-decode';

import { Capacitor } from '@capacitor/core';
import type { DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/react';
import type { VaultError } from '@ionic-enterprise/identity-vault';
import { DeviceSecurityType, VaultErrorCodes, VaultType } from '@ionic-enterprise/identity-vault';
import type { VaultInterface } from '@ionic-enterprise/identity-vault/dist/typings/VaultInterface';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import createVault from '../../createVaultFactory';
import { environment } from '../../environment/environment';
import { CheckUsVsCanada, isNative } from '../../helpers/utils.helper';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { CryptoService } from '../../services/crypto.service';
import type { RaygunUser } from '../../services/raygun.service';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import type { AppDispatch } from '../../store';
import { getLanguage, saveLanguagePreference } from '../Preferences/languageSlice';
import { showLoading } from '../tabs/tabsSlice';

const { logError, setRaygunUser } = RaygunErrorHandlerService();

export class AuthResult {
  token = '';
  use_location = false;
  use_text_entry = false;
  use_mood = false;
  use_badge = false;
  use_streak_component = false;
  use_streak_component_title = false;
  use_client_journal_selection = false;
  use_cravings_quick_checkin = false;
  use_scheduler = false;
  privacy = false;
  waiver = false;
  first_name = '';
  last_name = '';
  temporary = false;
  expired? = false;
  account_status?: 'archived' | null = null;
  use_apa_features = false;
  patient_consent_form_acknowledgement: boolean | null = null;
  use_qr_code = false;
}

export type Login = {
  email: string;
  password: string;
};

export type TokenValue = {
  organization_id: string;
  user_id: string;
  role: string;
  exp: number;
  region: string;
};

export enum UserOrganization {
  YALE = '416b1e9e-ce76-4d7a-9135-de40d0534913',
  APA = '8ceebcc8-2c82-49c7-ba46-1851079d10a0',
  MKI = 'bef03fbd-1ce5-44bf-8dac-593d6003963a',
  OSI = '2e129b2f-d501-474e-bed1-6ebce470d7f7',
  ONTRAK = 'b52211b2-1971-4adb-8dad-c4ec4d552f3a',
}

type LockType = DeviceSecurityType.None | DeviceSecurityType.Biometrics;

export const promptConfig = {
  iosBiometricsLocalizedCancelTitle: 'Cancel',
  iosBiometricsLocalizedReason: 'FaceID',
};

type ErrorTextAlias =
  | 'generic.checkWiFiAndTryAgain'
  | 'generic.errorMessage'
  | 'loginPage.loginArchivedClient'
  | 'loginPage.loginErrorMessage';

type LoginSliceType = {
  loginApiStatus: APIStatus;
  errorTextAlias: ErrorTextAlias | null;
  authResult: AuthResult;
  currentUser: TokenValue | undefined;
  refreshTokenStatus: APIStatus;
  resetPasswordStatus: APIStatus;
  doNotSendPasswordResetEmail: boolean;
  showBiometricErrorAlert: boolean;
  returningUser: boolean;
  lockType: DeviceSecurityType;
  canUseBiometrics: boolean;
  vault: VaultInterface | null;
  deviceInfo: DeviceInfo | null;
  notificationUrl: string;
  login: Login | null;
};

const initialState: LoginSliceType = {
  loginApiStatus: APIStatus.IDLE,
  refreshTokenStatus: APIStatus.IDLE,
  resetPasswordStatus: APIStatus.IDLE,
  doNotSendPasswordResetEmail: false,
  returningUser: true,
  authResult: new AuthResult(),
  currentUser: undefined,
  showBiometricErrorAlert: false,
  errorTextAlias: null,
  lockType: DeviceSecurityType.None,
  canUseBiometrics: false,
  vault: createVault({
    key: 'com.trycycledata',
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
    lockAfterBackgrounded: 5000,
    shouldClearVaultAfterTooManyFailedAttempts: true,
    customPasscodeInvalidUnlockAttempts: 2,
    unlockVaultOnLoad: false,
  }),
  deviceInfo: null,
  notificationUrl: '',
  login: null,
};

export const getVaultAuthResult = createAsyncThunk<AuthResult, undefined, AsyncThunkConfig>(
  'login/getVaultAuthResult',
  async (_, thunkAPI) => {
    const {
      login: { vault },
    } = thunkAPI.getState();
    try {
      if (!vault || (await vault.isEmpty())) {
        return new AuthResult();
      }
      const authResult = await vault.getValue<AuthResult>(environment.vaultKey);
      return authResult?.token ? authResult : new AuthResult();
    } catch (e) {
      const error = e as VaultError;
      logError(e, [
        'loginSlice',
        'getVaultAuthResult',
        'VaultError',
        `code: ${error.code}`,
        `message: ${error.message}`,
      ]);
      // https://ionic.io/docs/identity-vault/enums/vaulterrorcodes
      switch (error.code) {
        case VaultErrorCodes.UserCanceledInteraction:
        case VaultErrorCodes.AuthFailed:
        case VaultErrorCodes.AndroidBiometricsLockedOut:
        case VaultErrorCodes.iOSBiometricsLockedOut:
          return new AuthResult();
        case VaultErrorCodes.BiometricsNotEnabled:
        case VaultErrorCodes.InvalidatedCredential:
        case VaultErrorCodes.SecurityNotAvailable:
          thunkAPI.dispatch(showBiometricAlert(true));
          await thunkAPI.dispatch(updateVaultLockType(DeviceSecurityType.None));
          await thunkAPI.dispatch(removeVaultValue(false));
          return new AuthResult();
        default:
          return new AuthResult();
      }
    }
  },
);

export const getVaultLogin = createAsyncThunk<Login | null, undefined, AsyncThunkConfig>(
  'login/getVaultLogin',
  async (_, thunkAPI) => {
    const {
      login: { vault },
    } = thunkAPI.getState();
    try {
      const login = await vault?.getValue<Login>(environment.loginKey);
      return login ?? null;
    } catch (e) {
      const error = e as VaultError;
      logError(e, ['loginSlice', 'getVaultLogin', 'VaultError', `code: ${error.code}`, `message: ${error.message}`]);
      return null;
    }
  },
);

const saveBiometricsEmail = async (email: string) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  if (email) {
    const cryptedEmail = await CryptoService.encrypt(email, environment.cryptoKey, 'saveBiometricsEmail');
    await Preferences.set({
      key: environment.biometricsEmailKey,
      value: cryptedEmail,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const setVaultLogin = createAsyncThunk<void, undefined, AsyncThunkConfig>(
  'login/setVaultLogin',
  async (_, thunkAPI) => {
    const {
      login: { vault, login },
    } = thunkAPI.getState();
    try {
      if (vault && login) {
        await vault.setValue<Login>(environment.loginKey, login);
        await saveBiometricsEmail(login.email);
      }
      thunkAPI.dispatch(setLogin(null));
    } catch (e) {
      const error = e as VaultError;
      logError(e, ['loginSlice', 'setVaultLogin', 'VaultError', `code: ${error.code}`, `message: ${error.message}`]);
    }
  },
);

const getConfigUpdates = (lockType: LockType) => {
  switch (lockType) {
    case DeviceSecurityType.Biometrics:
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.Biometrics,
      };
    default:
      return {
        type: VaultType.SecureStorage,
        deviceSecurityType: DeviceSecurityType.None,
      };
  }
};

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const updateVaultLockType = createAsyncThunk<void, LockType, AsyncThunkConfig>(
  'login/updateVaultLockType',
  async (lockType, thunkAPI) => {
    const {
      login: { vault },
    } = thunkAPI.getState();
    try {
      const { type, deviceSecurityType } = getConfigUpdates(lockType);
      if (vault?.config) {
        await vault.updateConfig({ ...vault.config, type, deviceSecurityType });
        thunkAPI.dispatch(setLockType(lockType));
      }
    } catch (e) {
      const error = e as VaultError;
      logError(e, [
        'loginSlice',
        'updateVaultLockType',
        'VaultError',
        `code: ${error.code}`,
        `message: ${error.message}`,
      ]);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const removeVaultValue = createAsyncThunk<void, boolean, AsyncThunkConfig>(
  'login/removeVaultValue',
  async (isLogout, thunkAPI) => {
    const {
      login: { vault },
    } = thunkAPI.getState();
    try {
      await vault?.removeValue(environment.vaultKey);
      if (isLogout) {
        await vault?.lock();
      }
    } catch (e) {
      const error = e as VaultError;
      logError(e, ['loginSlice', 'removeVaultValue', 'VaultError', `code: ${error.code}`, `message: ${error.message}`]);
    }
  },
);

const saveWebUser = async (user: AuthResult) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  if (user?.token) {
    const cryptedAuthResult = await CryptoService.encrypt(JSON.stringify(user), environment.cryptoKey, 'saveWebUser');
    await Preferences.set({
      key: environment.authResultKey,
      value: cryptedAuthResult,
    });
  }
};

export const decodeToken = (user: AuthResult) => {
  try {
    if (user?.token) {
      return jwtDecode<TokenValue>(user.token);
    }
  } catch (error) {
    logError(error, ['loginSlice', 'decodeToken']);
  }
};

export const getUserOrganization = (user: AuthResult): UserOrganization | undefined => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    return decodedToken.organization_id as UserOrganization;
  }
};

export const getRegion = (user: AuthResult) => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    if (decodedToken.region === 'us-prod') {
      return 'US';
    }
  }
  return 'CA';
};

const setUserRaygun = (user: AuthResult) => {
  const decodedToken = decodeToken(user);
  if (decodedToken) {
    const raygunUser: RaygunUser = {
      role: decodedToken.role,
      user_id: decodedToken.user_id,
      organization_id: decodedToken.organization_id,
      region: getRegion(user),
    };
    setRaygunUser(raygunUser);
  }
};

export const updateUser = async (
  user: AuthResult,
  vault: VaultInterface | null,
  dispatch: AppDispatch,
  storeAuth: boolean,
) => {
  if (storeAuth) {
    if (isNative()) {
      try {
        await vault?.setValue(environment.vaultKey, user);
      } catch (e) {
        const error = e as VaultError;
        logError(error, ['loginSlice', 'updateUser', 'VaultError', `code: ${error.code}`, `message: ${error.message}`]);
        if (
          [
            VaultErrorCodes.BiometricsNotEnabled,
            VaultErrorCodes.InvalidatedCredential,
            VaultErrorCodes.SecurityNotAvailable,
          ].includes(error.code)
        ) {
          // To handle error for Android when user disabled device biometrics
          await dispatch(updateVaultLockType(DeviceSecurityType.None));
          await vault?.setValue(environment.vaultKey, user);
        }
      }
    } else {
      await saveWebUser(user);
    }
  }

  setUserRaygun(user);
  dispatch(updateAuth(user));
};

const handleSuccessfulLogin = async (
  authResult: AuthResult,
  vault: VaultInterface | null,
  notificationUrl: string,
  dispatch: AppDispatch,
  history: History,
  login: Login,
  biometrics: boolean,
) => {
  await updateUser(authResult, vault, dispatch, !authResult.temporary);
  const lng = getLanguage();
  if (lng) {
    await dispatch(saveLanguagePreference(lng));
  }

  if (isNative()) {
    const { Device } = await import('@capacitor/device');
    if (Capacitor.isPluginAvailable('Device')) {
      const info = await Device.getInfo();
      dispatch(setDeviceInfo(info));
    }
    if (!biometrics) {
      dispatch(setLogin(login));
    }
  }

  if (authResult.temporary) {
    dispatch(showLoading(false));
    history.push('/reset-password');
  } else {
    if (isNative() && isPlatform('ios') && !biometrics) {
      const { SavePassword } = await import('capacitor-ios-autofill-save-password');
      SavePassword.promptDialog({
        username: login.email,
        password: login.password,
      }).catch((error) => {
        logError(error, ['loginSlice', 'handleSuccessfulLogin', 'SavePassword', 'promptDialog']);
      });
    }
    if (!authResult.waiver) {
      dispatch(showLoading(false));
      history.push('/accepted_waiver');
    } else if (!authResult.privacy) {
      dispatch(showLoading(false));
      history.push('/privacy_policy');
    } else {
      if (notificationUrl) {
        history.push(notificationUrl);
        dispatch(setNotificationUrl(''));
      } else {
        history.push('/tabs/home');
      }
    }
  }
};

const handleLogin = async (
  responses: [[AuthResult], [AuthResult]] | undefined,
  vault: VaultInterface | null,
  notificationUrl: string,
  dispatch: AppDispatch,
  history: History,
  login: Login,
  biometrics: boolean,
) => {
  const authResult1 = responses?.[0]?.[0];
  const authResult2 = responses?.[1]?.[0];

  if (authResult1?.token) {
    handleSuccessfulLogin(authResult1, vault, notificationUrl, dispatch, history, login, biometrics);
  } else if (authResult2?.token) {
    handleSuccessfulLogin(authResult2, vault, notificationUrl, dispatch, history, login, biometrics);
  } else if (authResult1?.expired || authResult2?.expired) {
    dispatch(showLoading(false));
    dispatch(setApiStatusAndTextError({ loginApiStatus: APIStatus.EXPIRED }));
  } else if (authResult1?.account_status === 'archived' || authResult2?.account_status === 'archived') {
    dispatch(showLoading(false));
    dispatch(
      setApiStatusAndTextError({ loginApiStatus: APIStatus.FAILED, errorTextAlias: 'loginPage.loginArchivedClient' }),
    );
  } else {
    dispatch(showLoading(false));
    dispatch(
      setApiStatusAndTextError({
        loginApiStatus: APIStatus.FAILED,
        errorTextAlias: 'loginPage.loginErrorMessage',
      }),
    );
  }
};

export const doLogin = createAsyncThunk<
  [[AuthResult], [AuthResult]] | undefined,
  Login & { history: History; biometrics: boolean },
  AsyncThunkConfig
>('login/loggedIn', async ({ history, biometrics, ...login }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showLoading(true)); // loadHome will remove it
    const responses = await CheckUsVsCanada<[AuthResult]>('v2_login', {
      ...login,
      biometrics,
      isNative: isNative(),
    });
    const { vault, notificationUrl } = thunkAPI.getState().login;
    await handleLogin(responses, vault, notificationUrl, thunkAPI.dispatch, history, login, biometrics);
    return responses;
  } catch (e) {
    logError(e, ['loginSlice', 'loggedIn']);
    thunkAPI.dispatch(showLoading(false));
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

const checkAcceptPolicy = (authResult: AuthResult, history: History) => {
  if (!authResult.waiver) {
    history.push('/accepted_waiver');
  } else if (!authResult.privacy) {
    history.push('/privacy_policy');
  } else if (history && (history.location.pathname === '/reset-password' || history.location.pathname === '/login')) {
    history.push('/tabs/home');
  }
};

export const refreshToken = createAsyncThunk<
  [AuthResult],
  { history: History | null; isJournaling: boolean; storeAuth?: boolean },
  AsyncThunkConfig
>('login/refreshToken', async ({ history, isJournaling, storeAuth }, thunkAPI) => {
  try {
    const response = (await axios.post('v2_token_refresh', {
      isJournaling,
      isNative: isNative(),
    })) as [AuthResult];

    const [authResult] = response;
    if (authResult?.token) {
      const {
        login: { vault },
      } = thunkAPI.getState();
      await updateUser(authResult, vault, thunkAPI.dispatch, storeAuth ?? false);
      if (history && history.location && history.location.pathname !== '/privacy_policy') {
        checkAcceptPolicy(authResult, history);
      }
    }
    return response ?? [new AuthResult()];
  } catch (e) {
    logError(e, ['loginSlice', 'refreshToken']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const verifyToken = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'login/verifyToken',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v2_token_verify', {})) as boolean;
      return response ?? false;
    } catch (e) {
      logError(e, ['loginSlice', 'verifyToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const resetPassword = createAsyncThunk<
  [AuthResult],
  { history: History | null; password: string },
  AsyncThunkConfig
>('login/resetPassword', async ({ history, password }, thunkAPI) => {
  try {
    const response = (await axios.post('v2_password_reset', { password, isNative: isNative() })) as [AuthResult];

    const [authResult] = response;
    if (authResult?.token) {
      const { vault, login } = thunkAPI.getState().login;
      if (login) {
        thunkAPI.dispatch(setLogin({ ...login, password }));
      }
      await updateUser(authResult, vault, thunkAPI.dispatch, true);
      if (history && history.location && history.location.pathname !== '/privacy_policy') {
        checkAcceptPolicy(authResult, history);
      }
    }
    return response ?? [new AuthResult()];
  } catch (e) {
    logError(e, ['loginSlice', 'resetPassword']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetApiStatusAndErrorText: (state) => {
      state.errorTextAlias = null;
      state.loginApiStatus = APIStatus.IDLE;
    },
    setApiStatusAndTextError: (
      state,
      action: PayloadAction<{ loginApiStatus: APIStatus; errorTextAlias?: ErrorTextAlias }>,
    ) => {
      state.errorTextAlias = action.payload.errorTextAlias || null;
      state.loginApiStatus = action.payload.loginApiStatus;
    },
    updateAuth: (state, action: PayloadAction<AuthResult>) => {
      state.authResult = action.payload;
      state.currentUser = decodeToken(action.payload);
    },
    setLockType: (state, action: PayloadAction<DeviceSecurityType | undefined>) => {
      state.lockType = action.payload || DeviceSecurityType.None;
    },
    showBiometricAlert: (state, action: PayloadAction<boolean>) => {
      state.showBiometricErrorAlert = action.payload;
    },
    notSendResetPasswordEmail: (state) => {
      state.doNotSendPasswordResetEmail = !state.doNotSendPasswordResetEmail;
    },
    onBoardingClickNewUSer: (state) => {
      state.returningUser = false;
    },
    onBoardingClickReturningUser: (state) => {
      state.returningUser = true;
    },
    setCanUseBiometrics: (state, action: PayloadAction<boolean>) => {
      state.canUseBiometrics = action.payload;
    },
    setDeviceInfo: (state, action: PayloadAction<DeviceInfo>) => {
      state.deviceInfo = action.payload;
    },
    setNotificationUrl: (state, action: PayloadAction<string>) => {
      state.notificationUrl = action.payload;
    },
    setLogin: (state, action: PayloadAction<Login | null>) => {
      state.login = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state, _action) => {
        state.loginApiStatus = APIStatus.PENDING;
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        if (action.payload?.[0]?.[0]?.token || action.payload?.[1]?.[0]?.token) {
          state.loginApiStatus = APIStatus.FULFILLED;
        }
      })
      .addCase(doLogin.rejected, (state, action) => {
        if ((action.payload as ErrorMessage)?.displayText === 'Network Error') {
          state.errorTextAlias = 'generic.checkWiFiAndTryAgain';
        } else {
          state.errorTextAlias = 'generic.errorMessage';
        }
        state.loginApiStatus = APIStatus.ERROR;
      })
      .addCase(refreshToken.pending, (state, _action) => {
        state.refreshTokenStatus = APIStatus.PENDING;
      })
      .addCase(refreshToken.fulfilled, (state, _action) => {
        state.refreshTokenStatus = APIStatus.FULFILLED;
      })
      .addCase(refreshToken.rejected, (state, _action) => {
        state.errorTextAlias = 'generic.errorMessage';
        state.refreshTokenStatus = APIStatus.ERROR;
      })
      .addCase(resetPassword.pending, (state, _action) => {
        state.resetPasswordStatus = APIStatus.PENDING;
      })
      .addCase(resetPassword.fulfilled, (state, _action) => {
        state.resetPasswordStatus = APIStatus.FULFILLED;
      })
      .addCase(resetPassword.rejected, (state, _action) => {
        state.resetPasswordStatus = APIStatus.ERROR;
      });
  },
});

export const {
  resetApiStatusAndErrorText,
  setApiStatusAndTextError,
  updateAuth,
  notSendResetPasswordEmail,
  onBoardingClickNewUSer,
  onBoardingClickReturningUser,
  setLockType,
  showBiometricAlert,
  setDeviceInfo,
  setCanUseBiometrics,
  setNotificationUrl,
  setLogin,
} = loginSlice.actions;
