import i18n from 'i18next';
import type { DetectorOptions } from 'i18next-browser-languagedetector';
import LanguageDetector from 'i18next-browser-languagedetector';
import type { HttpBackendOptions } from 'i18next-http-backend';
import i18nHttpLoader from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { CheckUsVsCanada } from './helpers/utils.helper';
import { formatLanguage, getLanguage } from './pages/Preferences/languageSlice';
import { RaygunErrorHandlerService } from './services/raygun.service';
import cree from './Translations/cr.json';
import english from './Translations/en.json';
import spanish from './Translations/es.json';
import french from './Translations/fr.json';

const { logError } = RaygunErrorHandlerService();

export const resources = {
  en: { translation: english },
  cr: { translation: cree },
  fr: { translation: french },
  es: { translation: spanish },
} as const;

export type EnglishTranslation = typeof english;
type Namespace = keyof EnglishTranslation;
type Key = keyof EnglishTranslation[Namespace];

const options: DetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage', 'navigator', 'sessionStorage', 'htmlTag'],
  // cache user language on
  caches: ['localStorage'],
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

const getValue = (translationObj: unknown, missingKey: string) => {
  const [namespace, key] = missingKey.split('.') as [Namespace, Key];
  const translation = translationObj as EnglishTranslation;
  const result = translation?.[namespace]?.[key];

  if (result) {
    return result;
  } else {
    logError(new Error(`Missing translation for ${missingKey}.`), ['i18n', 'getValue']);
    return missingKey;
  }
};

const setErrorLanguage = () => {
  const lng = getLanguage() || '';
  if (lng.includes('es')) {
    return spanish;
  }
  if (lng.includes('cr')) {
    return cree;
  }
  if (lng.includes('fr')) {
    return french;
  }
  return english;
};

const loadResources = async (locale: string) => {
  try {
    const response = await CheckUsVsCanada<object>('v3_copy', { language_code: formatLanguage(locale) });
    if (response) {
      if (Object.keys(response[0]).length > 0 && response[0] !== null) {
        return response[0];
      }
      if (Object.keys(response[1]).length > 0 && response[1] !== null) {
        return response[1];
      }
    }
    return setErrorLanguage();
  } catch (e) {
    logError(e, ['i18n', 'loadResources']);
    return setErrorLanguage();
  }
};

const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}',

  request: async (_options, url, _payload, callback) => {
    try {
      const response = await loadResources(url);
      callback(null, {
        data: response,
        status: 200,
      });
    } catch (e) {
      logError(e, ['i18n', 'backendOptions', 'request']);
      callback(null, {
        data: english,
        status: 200,
      });
    }
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(i18nHttpLoader)
  .init({
    backend: backendOptions,
    detection: options,
    fallbackLng: getLanguage() ?? 'en-us',
    cleanCode: true,
    lowerCaseLng: true,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    parseMissingKeyHandler: function (key) {
      // uses backup key value
      const lng = getLanguage() || '';
      if (lng.includes('es')) {
        return getValue(spanish, key);
      }
      if (lng.includes('cr')) {
        return getValue(cree, key);
      }
      if (lng.includes('fr')) {
        return getValue(french, key);
      }
      return getValue(english, key);
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
