import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { isNative } from '../../helpers/utils.helper';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';
import { AuthResult, updateUser } from '../Login/loginSlice';

const { logError } = RaygunErrorHandlerService();

export type UpdatePassword = {
  old_password: string;
  new_password: string;
};

type UpdatePasswordSliceType = {
  loading: boolean;
  showOldPasswordError: boolean;
};

export const handleUpdatePassword = createAsyncThunk<[AuthResult], UpdatePassword, AsyncThunkConfig>(
  'updatePassword/handleUpdatePassword',
  async (updatePasswordData, thunkAPI) => {
    try {
      const response = (await axios.post('v3_update_password', { ...updatePasswordData, isNative: isNative() })) as [
        AuthResult,
      ];

      const [authResult] = response;
      if (authResult?.token) {
        const {
          login: { vault },
        } = thunkAPI.getState();
        await updateUser(authResult, vault, thunkAPI.dispatch, true);
      }
      return response ?? [new AuthResult()];
    } catch (e) {
      const error = e as AxiosError<ErrorMessage>;
      if (error.response?.data?.statusText === 'Password incorrect') {
        thunkAPI.dispatch(setOldPasswordError(true));
      }
      logError(e, ['updatePasswordSlice', 'handleUpdatePassword']);
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  },
);

const initialState: UpdatePasswordSliceType = {
  loading: false,
  showOldPasswordError: false,
};

export const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    setOldPasswordError: (state, action: PayloadAction<boolean>) => {
      state.showOldPasswordError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleUpdatePassword.pending, (state, _action) => {
        state.loading = true;
      })
      .addCase(handleUpdatePassword.fulfilled, (state, _action) => {
        state.loading = false;
      })
      .addCase(handleUpdatePassword.rejected, (state, _action) => {
        state.loading = false;
      });
  },
});

export const { setOldPasswordError } = updatePasswordSlice.actions;
