export const environment = {
  USEndpoint: 'https://api-dev.trycycle.io/client/',
  CAEndpoint: 'https://api-dev.trycycle.io/client/',
  buildNumber: "28960987",  
  versionNumber: "5.3.9",  
  environment_deploy: "dev",  
  rayGun: {
  apiKey: '19Ef2ju2ppmbEe8xOPBOA', 
  },
  posthogToken: 'phc_c1PYl38YxEtczRCgWF3CkGLrntfy1rqgVPRDS9Sror9', 
  authResultKey: '844ead44-6170-4699-ae1e-388aca72ce76', 
  vaultKey: '',
  loginKey: '',
  biometricsEmailKey: '',
  remeberEmailKey: '5113513a-6f08-4c88-acf3-4764733ad8d5',
  cryptoKey: 'eb81ce12-183b-426c-9d97-2cd0f8a10140', 
};
