import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { GroupedByMonth } from '../../components/Calendar/calendar.service';
import { formatByMonths } from '../../components/Calendar/calendar.service';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export type Feedback = {
  id: string;
  timestamp: string;
  emoji_hex_code: string;
  emoji_name: string;
  custom_message: string;
  clinician_name: string;
};

interface FeedbackSliceType {
  feedbackMonths: GroupedByMonth<Feedback>[];
  selectedFeedbackMonth: GroupedByMonth<Feedback> | undefined;
}

export const fetchFeedback = createAsyncThunk<Feedback[], undefined, AsyncThunkConfig>(
  'feedback/fetchFeedback',
  async (_, thunkAPI) => {
    try {
      if (navigator.onLine) {
        const response = (await axios.get('/v3_feedback')) as Feedback[];
        return response ?? [];
      }
      return [];
    } catch (e) {
      logError(e, ['feedbackSlice', 'fetchFeedback']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const initialState: FeedbackSliceType = {
  feedbackMonths: [],
  selectedFeedbackMonth: undefined,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    updateSelectedMonth(state, action: PayloadAction<GroupedByMonth<Feedback>>) {
      state.selectedFeedbackMonth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeedback.fulfilled, (state, action) => {
      const months = formatByMonths(action.payload);
      state.feedbackMonths = months;
      state.selectedFeedbackMonth = months[0];
    });
  },
});

export const { updateSelectedMonth } = feedbackSlice.actions;
