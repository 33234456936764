import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import type { UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { appUpdateSlice } from './components/AppUpdate/appUpdateSlice';
import { environment } from './environment/environment';
import { history } from './history';
import { badgesSlice } from './pages/Badges/BadgesSlice';
import { entriesSlice } from './pages/Entries/entriesSlice';
import { feedbackSlice } from './pages/Feedback/feedbackSlice';
import { forgotPasswordSlice } from './pages/ForgotPassword/forgotPasswordSlice';
import { streakSlice } from './pages/Home/streakSlice';
import { journalSlice } from './pages/Journal/journalSlice';
import { logout } from './pages/Login/Login.service';
import { getRegion, loginSlice } from './pages/Login/loginSlice';
import { patientConsentFormSlice } from './pages/PatientConsentForm/PatientConsentFormSlice';
import { getLanguage, languageSlice } from './pages/Preferences/languageSlice';
import { setErrorToast, showInternetError, TABS_MODAL, TABS_TOAST, tabsSlice } from './pages/tabs/tabsSlice';
import { updatePasswordSlice } from './pages/UpdatePassword/updatePasswordSlice';
import { waiverSlice } from './pages/Waivers/waiverSlice';
import { RaygunErrorHandlerService } from './services/raygun.service';

dayjs.extend(utc);
dayjs.extend(timezone);

const { logError } = RaygunErrorHandlerService();

const reducers = combineReducers({
  login: loginSlice.reducer,
  streaks: streakSlice.reducer,
  entries: entriesSlice.reducer,
  forgotPassword: forgotPasswordSlice.reducer,
  feedback: feedbackSlice.reducer,
  badges: badgesSlice.reducer,
  journal: journalSlice.reducer,
  updatePassword: updatePasswordSlice.reducer,
  waiver: waiverSlice.reducer,
  tabs: tabsSlice.reducer,
  language: languageSlice.reducer,
  PatientConsentForm: patientConsentFormSlice.reducer,
  appUpdate: appUpdateSlice.reducer,
});

type StateType = ReturnType<typeof reducers>;

const rootReducer = (state: StateType | undefined, action: UnknownAction) => {
  if (action.type === 'CLEAR_ALL_STATES') {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export const clearAllStates = () => {
  return { type: 'CLEAR_ALL_STATES' };
};

axios.defaults.responseType = 'json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['appversion'] = `${environment.versionNumber}.${environment.buildNumber}`;

let offlineAlertEnabled = true;
const setInterceptors = (createdStore: StoreType) => {
  const { dispatch } = createdStore;

  axios.interceptors.request.use(
    async (config) => {
      const online = navigator.onLine;
      if (online) {
        createdStore.dispatch(showInternetError(false));
        config.headers['timezone_name'] = dayjs.tz.guess();
        config.headers['content-location'] = dayjs.tz.guess();
        config.headers['accept-language'] = getLanguage() || 'en-us';
        const authResult = createdStore.getState().login.authResult;
        if (authResult?.token) {
          config.headers['Authorization'] = `Bearer ${authResult.token}`;
        }
        const region = getRegion(authResult);
        config.baseURL = region === 'CA' ? `${environment.CAEndpoint}` : `${environment.USEndpoint}`;
      } else {
        if (offlineAlertEnabled) {
          const { tabs } = createdStore.getState();
          if (tabs.modal !== TABS_MODAL.CHECKIN) {
            dispatch(showInternetError(true));
          }
        }
        setTimeout(() => {
          offlineAlertEnabled = true;
        }, 6000); //disable showing multiple alerts for 6secs
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next.data);
    },
    async (error) => {
      logError(error, ['store', 'Interceptor']);

      const config = error.response?.config;
      if (error.response && error.response.status === 401 && config?.url !== 'v2_token_verify') {
        await logout(createdStore.dispatch, true);
        if (history.location.pathname !== '/login') {
          history.push('/login');
        }
      } else if (
        !(
          config?.url === 'v2_token_verify' ||
          (config?.url === 'v3_update_password' && error.response?.data?.statusText === 'Password incorrect')
        )
      ) {
        dispatch(setErrorToast(TABS_TOAST.API_ERROR));
      }
      return Promise.reject(error);
    },
  );
};

export const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });
  setInterceptors(store);
  return store;
};

export const store = createStore();

type StoreType = ReturnType<typeof createStore>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = StateType;
export type AppDispatch = StoreType['dispatch'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
