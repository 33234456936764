import type { AxiosError } from 'axios';
import axios from 'axios';

import { Capacitor } from '@capacitor/core';
import type { DeviceInfo } from '@capacitor/device';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { environment } from '../../environment/environment';
import { CheckUsVsCanada } from '../../helpers/utils.helper';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export const showStores = async () => {
  const platform = Capacitor.getPlatform() as DeviceInfo['platform'];

  if (platform === 'ios') {
    window.open('https://apps.apple.com/us/app/trycycle/id1367938317', '_system');
  } else {
    try {
      window.open('market://details?id=com.trycycledata', '_system');
    } catch (e) {
      logError(e, ['appUpdateSlice', 'showStores']);
      window.open('https://play.google.com/store/apps/details?id=com.trycycledata', '_system');
    }
  }
};

type AppDetail = {
  app_version: string;
  build_number: string;
  operating_system: string;
  operating_system_version: string;
  phone_model: string;
  connection_type: string;
};

enum AppUpdateCode {
  LastNativeVersionError = -2,
  ReleaseDateError = -1,
  NoUpdateAvailable = 0,
  UpdateAvailable = 1,
  Expired = 3,
}

export const checkAppVersion = createAsyncThunk<[AppUpdateCode, AppUpdateCode], undefined, AsyncThunkConfig>(
  'appUpdate/checkAppVersion',
  async (_, thunkAPI) => {
    try {
      const appData: AppDetail = {
        app_version: environment.versionNumber,
        build_number: environment.buildNumber,
        operating_system: '',
        operating_system_version: '',
        phone_model: '',
        connection_type: '',
      };
      const { Device } = await import('@capacitor/device');
      if (Capacitor.isPluginAvailable('Device')) {
        const info = await Device.getInfo();
        appData.operating_system = info.platform;
        appData.operating_system_version = info.osVersion;
        appData.phone_model = info.manufacturer + ', Model: ' + info.model;
      }
      const { Network } = await import('@capacitor/network');
      if (Capacitor.isPluginAvailable('Network')) {
        const network = await Network.getStatus();
        appData.connection_type = network.connectionType;
      }

      const response = await CheckUsVsCanada<AppUpdateCode>('v2_upgrade', appData);
      return response ?? [AppUpdateCode.NoUpdateAvailable, AppUpdateCode.NoUpdateAvailable];
    } catch (e) {
      logError(e, ['appUpdateSlice', 'checkAppVersion']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const checkHasNotificationToken = createAsyncThunk<boolean | undefined, undefined, AsyncThunkConfig>(
  'appUpdate/checkHasNotificationToken',
  async (_, thunkAPI) => {
    try {
      const { Device } = await import('@capacitor/device');
      if (Capacitor.isPluginAvailable('Device')) {
        const { identifier: device_native_id } = await Device.getId();
        const response = (await axios.post('v4_has_notification_token', { device_native_id })) as boolean;
        return response;
      }
    } catch (e) {
      logError(e, ['appUpdateSlice', 'checkHasNotificationToken']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type AppUpdateSliceType = {
  appUpdateApiStatus: APIStatus;
  showExpired: boolean;
  showUpdateAlert: boolean;
  showLocationServicesDisabledModal: boolean;
};

const initialState: AppUpdateSliceType = {
  appUpdateApiStatus: APIStatus.IDLE,
  showExpired: false,
  showUpdateAlert: false,
  showLocationServicesDisabledModal: false,
};

export const appUpdateSlice = createSlice({
  name: 'appUpdate',
  initialState,
  reducers: {
    clearAppUpdateState: () => initialState,
    setShowLocationServicesDisabledModal: (state, action: PayloadAction<boolean>) => {
      state.showLocationServicesDisabledModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAppVersion.pending, (state, _action) => {
        state.appUpdateApiStatus = APIStatus.PENDING;
      })
      .addCase(checkAppVersion.fulfilled, (state, action) => {
        state.appUpdateApiStatus = APIStatus.FULFILLED;
        state.showExpired = action.payload.includes(AppUpdateCode.Expired);
        state.showUpdateAlert = action.payload.includes(AppUpdateCode.UpdateAvailable);
      })
      .addCase(checkAppVersion.rejected, (state, _action) => {
        state.appUpdateApiStatus = APIStatus.ERROR;
      });
  },
});

export const { clearAppUpdateState, setShowLocationServicesDisabledModal } = appUpdateSlice.actions;
