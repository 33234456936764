import React from 'react';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import { environment } from './environment/environment';
import i18n from './i18n';
import { AuthPersist } from './routes/AuthPersist';
import { store } from './store';

// https://posthog.com/docs/integrate/client/js#config
const options: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  property_denylist: ['email', 'password', 'new_password', 'old_password', 'diary'],
};

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <PostHogProvider apiKey={environment.posthogToken} options={options}>
            <AuthPersist>
              <App />
            </AuthPersist>
          </PostHogProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
  );
}
