import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ErrorMessage } from '../../models/error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun.service';

const { logError } = RaygunErrorHandlerService();

export type Lang = 'en-us' | 'cr' | 'fr-ca' | 'es-us';

type Language = {
  language_code: Lang;
  language_name: string;
  default: boolean;
};

type LanguageSliceType = {
  languageList: Language[];
  selectedLanguage: Lang;
};

export const fetchLanguage = createAsyncThunk<Language[], undefined, AsyncThunkConfig>(
  'language/fetchLanguage',
  async (_, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.post('/v3_language')) as Language[];
        return response ?? [];
      }
      return [];
    } catch (e) {
      logError(e, ['languageSlice', 'fetchLanguage']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const saveLanguagePreference = createAsyncThunk<string, string, AsyncThunkConfig>(
  'language/saveLanguagePreference',
  async (language_code, thunkAPI) => {
    try {
      const online = navigator.onLine;
      if (online) {
        const response = (await axios.post('/v3_save_language', { language_code })) as string;
        return response;
      }
      return '';
    } catch (e) {
      logError(e, ['languageSlice', 'saveLanguagePreference']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

const initialState: LanguageSliceType = {
  languageList: [],
  selectedLanguage: 'en-us',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state: LanguageSliceType, action: PayloadAction<Lang>) => {
      state.selectedLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLanguage.fulfilled, (state, action) => {
      state.languageList = action.payload;
      const storedLang = getLanguage();
      state.selectedLanguage =
        (storedLang ? storedLang : action.payload.find((l) => l.default)?.language_code) || 'en-us';
    });
  },
});

export const { setLanguage } = languageSlice.actions;

export const getLanguage = () => {
  return formatLanguage(localStorage.getItem('i18nextLng'));
};

export const formatLanguage = (lang: string | null) => {
  if (!lang) {
    return null;
  }

  //to support browser
  if (lang.includes('cr')) {
    return 'cr';
  }
  if (lang.includes('fr')) {
    return 'fr-ca';
  }
  if (lang.includes('es')) {
    return 'es-us';
  }
  if (lang.includes('en')) {
    return 'en-us';
  }
};
